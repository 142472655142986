import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-react-inputs/styles/material.css';

import useToast from 'hooks/useToast';
import Papa from 'papaparse';
import { FC, useState } from 'react';
import {
  GetSmallBusinessClassificationsQuery,
  NestedSmallBusinessClientAgency,
  SmallBusinessAgencyListQuery,
  SmallBusinessClient,
  SmallBusinessClientListQuery,
  useUpsertSmallBusinessAgencyMutation,
  useUpsertSmallBusinessClassificationMutation,
  useUpsertSmallBusinessClientMutation,
} from 'types/generated/graphql';
import { generateTransactionKey } from 'utils/general';

import { useApolloClient } from '@apollo/client';
import { Grid, SxProps, Theme } from '@mui/material';
import { L10n } from '@syncfusion/ej2-base';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';

import StyledButtonPrimary from '../ButtonPrimary';
import StyledButtonSecondary from '../ButtonSecondary';
import StyledDialog from '../Dialog';

const errorMessagesContainerStyle: SxProps<Theme> = (theme: Theme) => ({
  marginTop: theme.spacing(2),
});

type SmallBusinessClassificationsSelection = GetSmallBusinessClassificationsQuery['smallBusinessClassificationList'];
type SmallBusinessAgencyListSelection = SmallBusinessAgencyListQuery['smallBusinessAgencyList'];
type SmallBusinessClientListSelection = SmallBusinessClientListQuery['smallBusinessClientList'];

type CSVUploadDialogProps = {
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
  adminTableType?: string;
  smallBusinessClientList: SmallBusinessClientListSelection;
  smallBusinessClassificationsList: SmallBusinessClassificationsSelection;
  smallBusinessAgenciesList: SmallBusinessAgencyListSelection;
};

type TableRow = { [key: string]: any };

const CSVUploadDialog: FC<CSVUploadDialogProps> = ({
  isOpen,
  setIsOpen,
  adminTableType,
  smallBusinessClientList,
  smallBusinessClassificationsList,
  smallBusinessAgenciesList,
}) => {
  const apolloClient = useApolloClient();
  const { displayToast } = useToast();

  const [transactionKey, setTransactionKey] = useState<string>(generateTransactionKey());
  const [parsedData, setParsedData] = useState<any[]>([]);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const [upsertClientAgencyAndClassifications] = useUpsertSmallBusinessClientMutation();
  const [upsertSmallBusinessAgency] = useUpsertSmallBusinessAgencyMutation();
  const [createSmallBusinessClassification] = useUpsertSmallBusinessClassificationMutation();

  const onUploadSuccess = (args: any) => {
    Papa.parse(args.file.rawFile, {
      header: true,
      skipEmptyLines: true,
      complete: function (results: any) {
        setParsedData(results.data);
      },
    });
    setUploadSuccess(true);
  };

  L10n.load({
    'en-US': {
      uploader: {
        dropFilesHint: 'Or drop file here (1 file per upload)',
      },
    },
  });

  const handleClose = () => {
    setIsOpen(false);
    setTransactionKey(generateTransactionKey());
    setUploadSuccess(false);
  };

  const handleErrorDialogClose = () => {
    setIsErrorDialogOpen(false);
  };

  const validClients = smallBusinessClientList?.map((client) => client) ?? [];
  const validClientNames = validClients?.map((client) => client.name);
  const validAgencies = smallBusinessAgenciesList?.map((agency) => agency) ?? [];
  const validAgencyNames = validAgencies?.map((agency) => agency.name);
  const validClassificationNames = smallBusinessClassificationsList?.map((classification) => classification.name);
  const validClassificationAbbreviations = smallBusinessClassificationsList?.map(
    (classification) => classification.abbreviation,
  );
  const invalidClientsArr: any[] = [];
  const invalidAgencyArr: any[] = [];
  const invalidClassificationArr: any[] = [];

  const getRowsByName = (name: string) => {
    return parsedData.reduce<{ [name: string]: any[] }>((accumulator, row) => {
      if (!(row[name] in accumulator)) {
        accumulator[row[name]] = [];
      }
      accumulator[row[name]].push(row);
      return accumulator;
    }, {});
  };
  const getSbRequirementFromFirstRow = (tableRow: TableRow) => {
    const sbRequirement =
      tableRow['SB Requirement'].trim() === 'FALSE'
        ? false
        : tableRow['SB Requirement'].trim() === 'TRUE'
        ? true
        : null;

    return sbRequirement;
  };

  const getClassificationsForEachRow = (row: TableRow) => {
    const classifications =
      row['Classifications'] !== '' && row['Classifications']?.trim().includes(',')
        ? row['Classifications'].split(',').map((classification: string) => classification.trim())
        : [row['Classifications']?.trim()];

    return classifications ?? [];
  };

  const getParticipationPercentageForEachRow = (row: TableRow) => {
    const participationPercentage =
      row['Participation Percentage']?.trim() === '' ? 100 : parseFloat(row['Participation Percentage'].trim());
    return participationPercentage;
  };

  const getSpecialNoticeForEachRow = (row: TableRow) => {
    const specialNotice = row['Special Notice']?.trim() !== '' ? row['Special Notice'].trim() : '';
    return specialNotice;
  };

  const getExistingClassificationsFromDb = (row: TableRow) => {
    const classificationsPerRow = getClassificationsForEachRow(row) ?? [];

    return smallBusinessClassificationsList?.filter((smallBusinessClassification) =>
      classificationsPerRow.includes(smallBusinessClassification.abbreviation),
    );
  };

  const getClassificationIdsForClient = (row: TableRow) => {
    const existingClassifications = getExistingClassificationsFromDb(row);
    const classificationIdsForClient =
      row['Classifications']?.trim() !== ''
        ? existingClassifications.map((classification) => {
            return {
              smallBusinessClassificationId: classification.id,
            };
          })
        : [];
    return classificationIdsForClient;
  };

  const getClassificationIdsForAgency = (rows: TableRow[]) => {
    const smallBusinessClassificationIds = rows.map((row) => {
      const existingClassifications = getExistingClassificationsFromDb(row);

      const classificationIdsForAgency =
        row['Classifications']?.trim() !== '' ? existingClassifications.map((classification) => classification.id) : [];
      return classificationIdsForAgency.flat();
    });
    return smallBusinessClassificationIds.flat();
  };

  const getExistingAgencyId = (row: TableRow) => {
    return smallBusinessAgenciesList?.find((agency) => agency.name === row['Agency'].trim())?.id ?? '';
  };

  const checkDoesClientHaveValidAgencies = (rows: TableRow[]) => {
    return !rows.some(
      (row) =>
        row['Agency'] !== '' && !smallBusinessAgenciesList.find((agency) => agency.name === row['Agency'].trim()),
    );
  };

  const checkDoesDataHaveValidClassifications = (rows: TableRow[]) => {
    return !rows.some((row) => {
      const classificationsPerRow = getClassificationsForEachRow(row);

      return (
        row['Classifications'] !== '' &&
        !smallBusinessClassificationsList?.find((smallBusinessClassification) =>
          classificationsPerRow.includes(smallBusinessClassification.abbreviation),
        )
      );
    });
  };

  const getAgenciesAndClassificationsToSubmit = (rows: TableRow[], index: number) => {
    const agencyAndClassifications: NestedSmallBusinessClientAgency[] = [];
    rows.forEach((row) => {
      if (row['Agency'] !== '') {
        const existingAgencyId = getExistingAgencyId(row);
        const classificationIds = getClassificationIdsForClient(row);

        agencyAndClassifications.push({
          smallBusinessAgencyId: existingAgencyId,
          smallBusinessClientClassifications: classificationIds,
          smallBusinessClientId: `${transactionKey}.${index}`,
        });
      }
    });

    return agencyAndClassifications;
  };

  const getExistingAgenciesAndClassifications = (smallBusinessClient: SmallBusinessClient) => {
    const agencyAndClassifications: NestedSmallBusinessClientAgency[] = [];
    const smallBusinessClientId = smallBusinessClient?.id;

    smallBusinessClient?.smallBusinessClientAgencies?.forEach((agency) => {
      const smallBusinessClientClassifications =
        agency.smallBusinessClientClassifications?.map((classification) => {
          return {
            smallBusinessClassificationId: classification.smallBusinessClassification.id,
          };
        }) ?? [];
      agencyAndClassifications.push({
        smallBusinessAgencyId: agency.smallBusinessAgency.id,
        smallBusinessClientClassifications: smallBusinessClientClassifications,
        smallBusinessClientId: smallBusinessClientId,
      });
    });
    return agencyAndClassifications;
  };

  const getNewAndExistingClassifications = (smallBusinessClientAgency: NestedSmallBusinessClientAgency[]) => {
    const existingAgencyClassifications = smallBusinessClientAgency?.flatMap((agency) => {
      return agency.smallBusinessClientClassifications;
    });
    return existingAgencyClassifications;
  };

  const getFilteredRowDataWithInvalidInfo = (rows: TableRow[]) => {
    const filteredRowDataWithInvalidInfo = rows.filter((row) => {
      const existingClient = validClientNames.includes(row['Client']?.trim());
      const existingAgency = validAgencyNames.includes(row['Agency']?.trim());
      const existingClassifications = validClassificationNames.includes(row['Classification']?.trim());
      const classificationsPerRow = getClassificationsForEachRow(row);
      const nonExistingAgency = row['Agency'] !== '' && !existingAgency;
      const nonExistingClassifications =
        row['Classifications'] !== '' &&
        classificationsPerRow.some(
          (classification: string) => !validClassificationAbbreviations.includes(classification),
        );

      return adminTableType === 'clientAdminTable'
        ? nonExistingAgency || nonExistingClassifications || existingClient
        : adminTableType === 'agencyAdminTable'
        ? nonExistingClassifications || existingAgency
        : existingClassifications;
    });
    return filteredRowDataWithInvalidInfo;
  };

  const createArrOfInvalidData = (invalidArr: any[], client: string, agency: string) => {
    const arrOfInvalidInfo = invalidArr?.map((invalidItem) => {
      const classificationsPerRow = getClassificationsForEachRow(invalidItem);
      const invalidAgencies = !validAgencyNames.includes(invalidItem[agency]) ? invalidItem[agency] : '';
      const invalidClassifications = classificationsPerRow.filter((classification: string) =>
        !validClassificationAbbreviations.includes(classification) ? classification : '',
      );

      const invalidData = {
        name:
          adminTableType === 'clientAdminTable'
            ? invalidItem[client]
            : adminTableType === 'agencyAdminTable'
            ? invalidItem[agency]
            : '',
        invalidAgencies: adminTableType === 'clientAdminTable' ? invalidAgencies : '',
        invalidClassifications:
          adminTableType === 'clientAdminTable' || adminTableType === 'agencyAdminTable' ? invalidClassifications : '',
      };
      return invalidData;
    });
    return arrOfInvalidInfo;
  };

  const createArrOfNonDuplicateNames = (invalidDataArr: any[], rowData: string) => {
    return invalidDataArr.reduce((accumulator, invalidItem) => {
      if (!accumulator.some((item: any) => item[rowData] === invalidItem[rowData])) {
        accumulator.push(invalidItem);
      }
      return accumulator;
    }, []);
  };

  const getAllClientsWithInvalidInfo = () => {
    const arrOfInvalidClientInfo = createArrOfInvalidData(invalidClientsArr, 'Client', 'Agency');
    const nonDuplicateClientNames = createArrOfNonDuplicateNames(invalidClientsArr, 'Client');

    return nonDuplicateClientNames.map((client: { Client: string }) => {
      const filterClientsWithInvalidInfoByName = arrOfInvalidClientInfo.filter(
        (invalidClientItem) => client['Client'] === invalidClientItem?.name,
      );
      const agencyArr = filterClientsWithInvalidInfoByName.map(
        (invalidClientItem) => invalidClientItem?.invalidAgencies,
      );
      const classificationArr = filterClientsWithInvalidInfoByName.flatMap(
        (invalidClientItem) => invalidClientItem?.invalidClassifications,
      );

      return {
        name: client['Client'],
        agencies: agencyArr.filter((agency) => agency),
        classifications: classificationArr.filter((classification) => classification),
      };
    });
  };

  const getAllAgenciesWithInvalidInfo = () => {
    const arrOfInvalidAgencyInfo = createArrOfInvalidData(invalidAgencyArr, '', 'Agency');
    const nonDuplicateAgencyNames = createArrOfNonDuplicateNames(invalidAgencyArr, 'Agency');

    return nonDuplicateAgencyNames.map((agency: { Agency: string }) => {
      const filterAgenciesWithInvalidInfoByName = arrOfInvalidAgencyInfo.filter(
        (invalidAgencyItem) => agency['Agency'] === invalidAgencyItem.name,
      );
      const classificationArr = filterAgenciesWithInvalidInfoByName.flatMap(
        (invalidAgencyItem) => invalidAgencyItem.invalidClassifications,
      );

      return {
        name: agency['Agency'],
        classifications: classificationArr.filter((classification) => classification),
      };
    });
  };

  const getAllClassificationsWithInvalidInfo = () => {
    const nonDuplicateClassificationNames = createArrOfNonDuplicateNames(invalidClassificationArr, 'Classification');
    const classificationsWithInvalidInfo = nonDuplicateClassificationNames.map(
      (classification: { Classification: string }) => {
        return {
          name: classification.Classification,
        };
      },
    );
    return classificationsWithInvalidInfo;
  };

  const generateErrorMessageForClients = () => {
    const clientsWithInvalidInfo = getAllClientsWithInvalidInfo();

    const errorMessage = clientsWithInvalidInfo.map(
      (client: { name: string; agencies: string[]; classifications: string[] }) => {
        let errorTemplate = '';

        if (client.agencies.length || client.classifications.length) {
          const agencies = client.agencies.length ? `Agencies: ${client.agencies.join(', ')}, ` : '';
          const classifications = client.classifications.length
            ? `Classifications: ${client.classifications.join(', ')}, `
            : '';

          errorTemplate = `${client.name} was not uploaded because it has invalid information: 
                ${agencies}
                ${classifications}`;
          errorTemplate.replace(/,\s*$/, '');
        } else {
          errorTemplate = `${client.name} was not uploaded because it already exists.`;
        }

        errorTemplate = errorTemplate.slice(-2) === ', ' ? errorTemplate.slice(0, -2) + '.' : errorTemplate;
        return errorTemplate ? errorTemplate : `${client.name} was not uploaded for an unknown reason.`;
      },
    );
    setErrorMessages(errorMessage);
  };

  const generateErrorMessageForAgencies = () => {
    const agenciesWithInvalidInfo = getAllAgenciesWithInvalidInfo();

    const errorMessage = agenciesWithInvalidInfo.map((agency: { name: string; classifications: string[] }) => {
      let errorTemplate = '';

      if (agency.classifications.length) {
        const classifications = agency.classifications.length
          ? `Classifications: ${agency.classifications.join(', ')}, `
          : '';

        errorTemplate = `${agency.name} was not uploaded because it has invalid information: 
              ${classifications}`;
        errorTemplate.replace(/,\s*$/, '');
      } else {
        errorTemplate = `${agency.name} was not uploaded because it already exists.`;
      }

      errorTemplate = errorTemplate.slice(-2) === ', ' ? errorTemplate.slice(0, -2) + '.' : errorTemplate;
      return errorTemplate ? errorTemplate : `${agency.name} was not uploaded for an unknown reason.`;
    });
    setErrorMessages(errorMessage);
  };

  const generateErrorMessageForClassifications = () => {
    const classificationsWithInvalidInfo = getAllClassificationsWithInvalidInfo();

    const errorMessage = classificationsWithInvalidInfo.map((classification: { name: string }) => {
      let errorTemplate = '';
      errorTemplate = `${classification.name} was not uploaded because it already exists.`;
      errorTemplate = errorTemplate.slice(-2) === ', ' ? errorTemplate.slice(0, -2) + '.' : errorTemplate;
      return errorTemplate ? errorTemplate : `${classification.name} was not uploaded for an unknown reason.`;
    });
    setErrorMessages(errorMessage);
  };

  const handleSubmitForClientAdminTable = () => {
    const rowsByClientName = getRowsByName('Client');

    Object.values(rowsByClientName).forEach((rows, index) => {
      const firstRow = rows[0] ?? null;
      const clientName = firstRow['Client'].trim();
      const abbreviation = firstRow['Abbreviation'].trim() ?? '';
      const sbRequirement = getSbRequirementFromFirstRow(firstRow);
      const checkDoesClientExist = validClientNames?.includes(clientName);
      const doesClientHaveValidAgencies = checkDoesClientHaveValidAgencies(rows);
      const doesClientHaveValidClassifications = checkDoesDataHaveValidClassifications(rows);
      const agencyAndClassifications: NestedSmallBusinessClientAgency[] = getAgenciesAndClassificationsToSubmit(
        rows,
        index,
      );
      const smallBusinessClient = validClients?.find((client) => client.name === clientName) as SmallBusinessClient;
      const existingAgenciesAndClassifications = smallBusinessClient
        ? getExistingAgenciesAndClassifications(smallBusinessClient) ?? []
        : [];
      const existingAgencyClassifications = getNewAndExistingClassifications(existingAgenciesAndClassifications);
      const newAgencyClassifications = getNewAndExistingClassifications(agencyAndClassifications);

      if (!checkDoesClientExist && doesClientHaveValidAgencies && doesClientHaveValidClassifications) {
        upsertClientAgencyAndClassifications({
          variables: {
            input: {
              transactionKey: `${transactionKey}.${index}`,
              name: clientName,
              abbreviation: abbreviation ?? '',
              isFederal: sbRequirement,
              isActive: true,
              smallBusinessClientAgencies: agencyAndClassifications,
            },
          },
        }).then(() => {
          apolloClient.reFetchObservableQueries();
          displayToast('The clients were added successfully', 'success');
        });
      } else if (
        checkDoesClientExist &&
        doesClientHaveValidAgencies &&
        doesClientHaveValidClassifications &&
        agencyAndClassifications.length &&
        (!smallBusinessClient?.smallBusinessClientAgencies.length ||
          existingAgenciesAndClassifications.length < agencyAndClassifications.length ||
          existingAgencyClassifications.length < newAgencyClassifications.length)
      ) {
        upsertClientAgencyAndClassifications({
          variables: {
            input: {
              id: smallBusinessClient?.id,
              name: smallBusinessClient?.name as string,
              abbreviation: smallBusinessClient?.abbreviation ?? '',
              isFederal: smallBusinessClient?.isFederal,
              isActive: smallBusinessClient?.isActive,
              smallBusinessClientAgencies: agencyAndClassifications,
            },
          },
        }).then(() => {
          apolloClient.reFetchObservableQueries();
          displayToast('The clients were updated successfully', 'success');
        });
      } else {
        setIsErrorDialogOpen(true);
        const filterClientsWithInvalidInfo = getFilteredRowDataWithInvalidInfo(rows);
        filterClientsWithInvalidInfo.forEach((client) => invalidClientsArr.push(client));
        generateErrorMessageForClients();
      }
    });
  };

  const handleSubmitForAgencyAdminTable = () => {
    const rowsByAgencyName = getRowsByName('Agency');

    Object.values(rowsByAgencyName).forEach((rows, index) => {
      const firstRow = rows[0] ?? null;
      const agencyName = firstRow['Agency'].trim();
      const abbreviation = firstRow['Abbreviation'].trim() ?? '';
      const url = firstRow['Documentation'] ?? '';
      const sbRequirement = getSbRequirementFromFirstRow(firstRow);
      const participationPercent = getParticipationPercentageForEachRow(firstRow);
      const specialNotice = getSpecialNoticeForEachRow(firstRow);
      const checkDoesAgencyExist = validAgencyNames?.includes(firstRow['Agency'].trim());
      const doesAgencyHaveValidClassifications = checkDoesDataHaveValidClassifications(rows);
      const smallBusinessClassificationIds = getClassificationIdsForAgency(rows);
      const smallBusinessAgencies = validAgencies?.find((agency) => agency.name === agencyName);
      const existingAgencyClassificationIds = smallBusinessAgencies?.smallBusinessAgencyClassifications?.map(
        (agency) => agency.smallBusinessClassification.id ?? [],
      );

      if (!checkDoesAgencyExist && doesAgencyHaveValidClassifications) {
        upsertSmallBusinessAgency({
          variables: {
            input: {
              transactionKey: `${transactionKey}.${index}`,
              name: agencyName,
              abbreviation: abbreviation,
              smallBusinessClassificationIds: smallBusinessClassificationIds ?? [],
              url: url,
              isFederal: sbRequirement,
              isActive: true,
              participationPercentage: participationPercent,
              specialNotice: specialNotice,
            },
          },
        }).then(() => {
          apolloClient.reFetchObservableQueries();
          displayToast('The agencies were added successfully', 'success');
        });
      } else if (
        checkDoesAgencyExist &&
        doesAgencyHaveValidClassifications &&
        smallBusinessClassificationIds.length &&
        existingAgencyClassificationIds &&
        existingAgencyClassificationIds.length < smallBusinessClassificationIds.length
      ) {
        upsertSmallBusinessAgency({
          variables: {
            input: {
              id: smallBusinessAgencies?.id,
              name: smallBusinessAgencies?.name as string,
              abbreviation: smallBusinessAgencies?.abbreviation ?? '',
              smallBusinessClassificationIds: smallBusinessClassificationIds ?? [],
              url: smallBusinessAgencies?.url ?? '',
              isFederal: smallBusinessAgencies?.isFederal,
              isActive: smallBusinessAgencies?.isActive,
            },
          },
        }).then(() => {
          apolloClient.reFetchObservableQueries();
          displayToast('The agencies were updated successfully', 'success');
        });
      } else {
        setIsErrorDialogOpen(true);
        const filterAgenciesWithInvalidInfo = getFilteredRowDataWithInvalidInfo(rows);
        filterAgenciesWithInvalidInfo.forEach((agency) => invalidAgencyArr.push(agency));
        generateErrorMessageForAgencies();
      }
    });
  };

  const handleSubmitForClassificationAdminTables = () => {
    const rowsByClassificationName = getRowsByName('Classification');

    Object.values(rowsByClassificationName).forEach((rows, index) => {
      const firstRow = rows[0] ?? null;
      const classificationName = firstRow['Classification'].trim();
      const abbreviation = firstRow['Abbreviation'].trim() ?? '';
      const sortOrder = firstRow['sortOrder']++;
      const sbRequirement = getSbRequirementFromFirstRow(firstRow);
      const checkDoesClassificationExist = validClassificationNames?.includes(firstRow['Classification'].trim());

      if (!checkDoesClassificationExist) {
        createSmallBusinessClassification({
          variables: {
            input: {
              transactionKey: `${transactionKey}.${index}`,
              name: classificationName,
              abbreviation: abbreviation,
              sortOrder: sortOrder,
              isFederal: sbRequirement,
            },
          },
        }).then(() => {
          apolloClient.reFetchObservableQueries();
          displayToast('The classifications were added successfully', 'success');
        });
      } else {
        setIsErrorDialogOpen(true);
        const filterClassificationsWithInvalidInfo = getFilteredRowDataWithInvalidInfo(rows);
        filterClassificationsWithInvalidInfo.forEach((classification) => invalidClassificationArr.push(classification));
        generateErrorMessageForClassifications();
      }
    });
  };

  const generateDialogTitle = () => {
    return adminTableType === 'clientAdminTable'
      ? 'Mass CSV Client Upload'
      : adminTableType === 'agencyAdminTable'
      ? 'Mass CSV Agency Upload'
      : 'Mass CSV Classification Upload';
  };

  const generateErrorDialogTitle = () => {
    return adminTableType === 'clientAdminTable'
      ? 'Error While Uploading Clients'
      : adminTableType === 'agencyAdminTable'
      ? 'Error While Uploading Agencies'
      : 'Error While Uploading Classifications';
  };

  const onSubmitSuccess = () => {
    if (adminTableType === 'clientAdminTable') {
      handleSubmitForClientAdminTable();
    } else if (adminTableType === 'agencyAdminTable') {
      handleSubmitForAgencyAdminTable();
    } else {
      handleSubmitForClassificationAdminTables();
    }
    handleClose();
  };

  return (
    <>
      <StyledDialog
        title={generateDialogTitle()}
        content={
          <UploaderComponent
            id="massFileUpload"
            type="file"
            autoUpload={true}
            buttons={{ browse: 'Choose file' }}
            multiple={false}
            asyncSettings={{ saveUrl: 'https://services.syncfusion.com/react/production/api/FileUploader/Save' }}
            success={onUploadSuccess}
            allowedExtensions=".csv"
          />
        }
        actions={
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <StyledButtonSecondary disabled={false} label={'cancel'} onClick={handleClose} />
                </Grid>
                <Grid item>
                  <StyledButtonPrimary
                    label={'submit'}
                    type="submit"
                    onClick={onSubmitSuccess}
                    disabled={!uploadSuccess}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        isOpen={isOpen}
        handleClose={handleClose}
        fullWidth={true}
        maxWidth={'md'}
        disableEscapeKeyDown={true}
      />
      {!!errorMessages?.length && (
        <StyledDialog
          title={generateErrorDialogTitle()}
          content={
            <>
              <Grid container>
                <Grid item>
                  <ul>
                    {errorMessages.map((errorMessage: string, index: number) => (
                      <li key={index}>{errorMessage}</li>
                    ))}
                  </ul>
                </Grid>
              </Grid>
              <Grid container sx={errorMessagesContainerStyle}>
                <Grid item>{'Please update the csv and try again.'}</Grid>
              </Grid>
            </>
          }
          actions={
            <Grid container>
              <Grid item xs={12}>
                <StyledButtonSecondary disabled={false} label={'close'} onClick={handleErrorDialogClose} />
              </Grid>
            </Grid>
          }
          isOpen={isErrorDialogOpen}
          handleClose={handleErrorDialogClose}
          maxWidth={'md'}
          disableEscapeKeyDown={true}
        />
      )}
    </>
  );
};

export default CSVUploadDialog;
